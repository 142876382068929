import { SvgIcon, SvgIconProps } from "@mui/material";

export function IconClipboard(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <path
                    d="M12 3C12.6975 3 13.0462 3 13.3323 3.07667C14.1088 3.28472 14.7153 3.8912 14.9233 4.66766C15 4.95378 15 5.30252 15 6V12.9C15 14.1601 15 14.7902 14.7548 15.2715C14.539 15.6948 14.1948 16.039 13.7715 16.2548C13.2902 16.5 12.6601 16.5 11.4 16.5H6.6C5.33988 16.5 4.70982 16.5 4.22852 16.2548C3.80516 16.039 3.46095 15.6948 3.24524 15.2715C3 14.7902 3 14.1601 3 12.9V6C3 5.30252 3 4.95378 3.07667 4.66766C3.28472 3.8912 3.8912 3.28472 4.66766 3.07667C4.95378 3 5.30252 3 6 3M7.2 4.5H10.8C11.22 4.5 11.4301 4.5 11.5905 4.41826C11.7316 4.34635 11.8463 4.23161 11.9183 4.09049C12 3.93006 12 3.72004 12 3.3V2.7C12 2.27996 12 2.06994 11.9183 1.90951C11.8463 1.76839 11.7316 1.65365 11.5905 1.58175C11.4301 1.5 11.22 1.5 10.8 1.5H7.2C6.77996 1.5 6.56994 1.5 6.40951 1.58175C6.26839 1.65365 6.15365 1.76839 6.08175 1.90951C6 2.06994 6 2.27996 6 2.7V3.3C6 3.72004 6 3.93006 6.08175 4.09049C6.15365 4.23161 6.26839 4.34635 6.40951 4.41826C6.56994 4.5 6.77996 4.5 7.2 4.5Z"
                    // stroke="#73767C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
}
