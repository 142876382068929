import React from "react";

import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton, SxProps, Typography } from "@mui/material";

export interface CounterProps {
    count: number;
    increase: () => void;
    decrease: () => void;
    disabled?: boolean;
    increaseDisabled?: boolean;
    decreaseDisabled?: boolean;
    placeholder?: string;
    sx?: SxProps;
    typoBoxSx?: SxProps;
}

const Counter: React.FC<CounterProps> = ({
    count,
    increase,
    decrease,
    disabled = false,
    increaseDisabled = false,
    decreaseDisabled = false,
    placeholder,
    sx,
    typoBoxSx,
}) => {
    const themeColor = "#F9BD38";
    const darkThemeColor = "#D99D18";
    const disabledColor = "#D1D3D6";
    const iconColor = "#383B40";
    const disabledIconColor = "#91949A";

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                border: `1px solid #D1D3D6`,
                borderRadius: "10px",
                height: "38px",
                backgroundColor: "white",
                paddingX: "9px",
                gap: "20px",
                ...sx,
            }}
        >
            <IconButton
                sx={{
                    height: "100%",
                    borderRadius: "10px",
                    // paddingLeft: "9px",
                    padding: 0,
                    ":disabled": {
                        color: disabledColor,
                    },
                }}
                onClick={decrease}
                disabled={disabled || decreaseDisabled}
            >
                <Remove
                    htmlColor={iconColor}
                    fontSize="small"
                    sx={{
                        color: disabled || decreaseDisabled ? disabledIconColor : iconColor,
                    }}
                />
            </IconButton>
            <Box
                sx={{
                    width: "auto",
                    textAlign: "center",
                    ...typoBoxSx,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                    }}
                >
                    {placeholder && placeholder.length > 0 ? placeholder : count}
                </Typography>
            </Box>
            <IconButton
                onClick={increase}
                disabled={disabled || increaseDisabled}
                sx={{
                    height: "100%",
                    borderRadius: "10px",
                    // paddingRight: "9px",
                    padding: 0,
                    ":disabled": {
                        color: disabledColor,
                    },
                }}
            >
                <Add
                    htmlColor={iconColor}
                    fontSize="small"
                    sx={{
                        color: disabled || increaseDisabled ? disabledIconColor : iconColor,
                    }}
                />
            </IconButton>
        </Box>
    );
};

export default Counter;
