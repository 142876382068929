import React from "react";

import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import ImageViewerProps from "./ImageViewerProps";

// const NORMAL_WIDTH = "80%";
// const NORMAL_HEIGHT = "70%";
const FULL_LENGTH = "100%";

const ImageViewer: React.FC<ImageViewerProps> = ({ src, onClick, fitToWidth = false }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    return (
        <Fade in={!!src} style={{ width: "100%" }}>
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    backgroundColor: "rgba(0,0,0,0.4)",
                    height: "100vh",
                    left: 0,
                    position: "fixed",
                    top: 0,
                    width: "100vw",
                    zIndex: 9999,
                }}
                onClick={onClick}
            >
                <Box
                    // width={NORMAL_WIDTH}
                    // height={NORMAL_HEIGHT}
                    sx={{
                        overflow: fitToWidth ? "scroll" : undefined,
                        pb: isMobile ? 15 : 0,
                    }}
                >
                    <img
                        src={src ?? ""}
                        alt={"자세히보기"}
                        width={isMobile ? window.screen.width : 700}
                        height={isMobile ? "auto" : FULL_LENGTH}
                        style={{ objectFit: "fill" }}
                    />
                </Box>
            </Grid>
        </Fade>
    );
};

export default ImageViewer;
