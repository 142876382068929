import { PropsWithChildren, ReactNode } from "react";

import { Box, IconButton, Typography } from "@mui/material";

import { tmrColorPalette, zIndex } from "../consts";
import { IconArrowBack } from "../icons/arrowBack";

export function FixedHeader({
    handleClickBackButton,
    title,
    RightComponent,
    top,
}: PropsWithChildren<{
    handleClickBackButton?: () => void;
    title: string;
    RightComponent?: ReactNode;
    top?: number;
}>) {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
                position: "sticky",
                height: "54px",
                backgroundColor: tmrColorPalette.white[100],
                borderBottom: `1px solid ${tmrColorPalette.grey[200]}`,
                top,
                zIndex: zIndex.stickyHeader,
            }}
        >
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: "20px",
                }}
            >
                <IconButton
                    onClick={() => {
                        handleClickBackButton && handleClickBackButton();
                        // : window.history.length > 1
                        // ? navi(-1)
                        // : navi("../");
                    }}
                >
                    <IconArrowBack />
                </IconButton>
                <Box width={20} height={40} />
            </Box>
            <Typography
                variant="f18B"
                sx={{
                    color: tmrColorPalette.grey[900],
                }}
            >
                {title}
            </Typography>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    position: "absolute",
                    top: 0,
                    right: "20px",
                    bottom: 0,
                }}
            >
                {RightComponent}
            </Box>
        </Box>
    );
}

FixedHeader.displayName = "FixedHeader";
