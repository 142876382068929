import { SvgIcon, SvgIconProps } from "@mui/material";

export function IconPlaceFlag(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
            >
                <g filter="url(#filter0_d_8059_961)">
                    <circle
                        cx="13"
                        cy="12"
                        r="11"
                        fill="#FF305D"
                        stroke="white"
                        strokeWidth="2"
                    />
                    <path
                        d="M15.4667 7C17.58 7 19 9.04875 19 10.96C19 14.8306 13.1067 18 13 18C12.8933 18 7 14.8306 7 10.96C7 9.04875 8.42 7 10.5333 7C11.7467 7 12.54 7.62563 13 8.17562C13.46 7.62563 14.2533 7 15.4667 7Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_d_8059_961"
                        x="0"
                        y="0"
                        width="26"
                        height="26"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.577641 0 0 0 0 0.577641 0 0 0 0 0.577641 0 0 0 0.15 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_8059_961"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_8059_961"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}
