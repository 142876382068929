import { SvgIcon, SvgIconProps } from "@mui/material";

export function IconArrowNarrowRight(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M3.33334 10H16.6667M16.6667 10L11.6667 5M16.6667 10L11.6667 15"
                    // stroke="#030404"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
}
