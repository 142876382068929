import { PropsWithChildren, ReactNode } from "react";

import { Box } from "@mui/material";

import { BottomFixedButtonWrapper } from "./BottomFixedButton/Wrapper";
import { FixedHeader } from "./FixedHeader";

export function HeaderWithBottomFixedButtonLayout({
    children,
    handleClickBackButton,
    title,
    BottomButton,
    BottomExtraComponent,
    TopHeaderRightComponent,
}: PropsWithChildren<{
    handleClickBackButton?: () => void;
    title: string;
    BottomButton?: ReactNode;
    BottomExtraComponent?: ReactNode;
    TopHeaderRightComponent?: ReactNode;
}>) {
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
                margin: "0 auto",
                width: "100%",
                // maxWidth: "430px",
                height: "100dvh",
            }}
        >
            <FixedHeader
                handleClickBackButton={handleClickBackButton}
                title={title}
                RightComponent={TopHeaderRightComponent}
            />

            <Box
                flex={1}
                sx={{
                    overflow: "auto",
                    position: "relative",
                }}
            >
                {children}
            </Box>
            {BottomExtraComponent && BottomExtraComponent}
            {BottomButton && (
                <BottomFixedButtonWrapper>{BottomButton}</BottomFixedButtonWrapper>
            )}
        </Box>
    );
}

HeaderWithBottomFixedButtonLayout.displayName = "HeaderWithBottomFixedButtonLayout";
