import { Box, Typography } from "@mui/material";

import { tmrColorPalette } from "../consts";

export function PromoLabel({ title }: { title: string }) {
    return (
        <Box
            sx={{
                backgroundColor: tmrColorPalette.negative[100],
                padding: "4px 8px",
                display: "inline-flex",
                textAlign: "center",
                minHeight: "22px",
                borderRadius: "5px",
                marginTop: "8px",
            }}
        >
            <Typography
                variant="Label1NormalSemiBold"
                sx={{
                    color: tmrColorPalette.negative[700],
                    whiteSpace: "pre",
                }}
            >
                {title}
            </Typography>
        </Box>
    );
}
