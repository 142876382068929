import { SvgIcon, SvgIconProps } from "@mui/material";

export function IconTarget(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
            >
                <path
                    d="M25.6663 14H20.9997M6.99967 14H2.33301M13.9997 6.99998V2.33331M13.9997 25.6666V21M23.333 14C23.333 19.1546 19.1543 23.3333 13.9997 23.3333C8.84502 23.3333 4.66634 19.1546 4.66634 14C4.66634 8.84532 8.84502 4.66665 13.9997 4.66665C19.1543 4.66665 23.333 8.84532 23.333 14Z"
                    // stroke="#030404"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
}
