export const zIndex = {
    hide: -1,
    base: 0,
    stickyHeader: 500,
    dropdown: 1000,
    modal: 2000,
    overlay: 3000,
    toast: 4000,
    alwaysOnTop: 9999,
} as const;
