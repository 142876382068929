import { SvgIcon, SvgIconProps } from "@mui/material";

export function IconMap(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
            >
                <path
                    d="M10.5007 20.9997L2.33398 25.6663V6.99967L10.5007 2.33301M10.5007 20.9997L18.6673 25.6663M10.5007 20.9997V2.33301M18.6673 25.6663L25.6673 20.9997V2.33301L18.6673 6.99967M18.6673 25.6663V6.99967M18.6673 6.99967L10.5007 2.33301"
                    // stroke="#1D1F22"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
}
