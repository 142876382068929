import { PropsWithChildren } from "react";

import { Box, SxProps } from "@mui/material";

import { tmrColorPalette } from "../../consts";

export function BottomFixedButtonWrapper({
    children,
    isPositionFixed,
    sx,
}: PropsWithChildren<{
    isPositionFixed?: boolean;
    sx?: SxProps;
}>) {
    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
                position: isPositionFixed ? "fixed" : "relative",
                ...(isPositionFixed && {
                    bottom: 0,
                }),
                padding: "15px 20px 30px",
                width: "100%",
                // maxWidth: "430px",
                minHeight: "98px",
                height: "auto",
                backgroundColor: tmrColorPalette.white[100],
                borderTop: `0.5px solid ${tmrColorPalette.grey[200]}`,
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}
