import { MouseEventHandler, SyntheticEvent, useState } from "react";

import { Box, SxProps } from "@mui/material";

import { Skeleton } from "../Skeleton/Skeleton";

type ImageEvent = SyntheticEvent<HTMLImageElement, Event>;

// 스켈리톤 -> (썸네일) -> 원본 -> (펄백)
export function ImageWithSkeleton({
    src: srcProp,
    thumbnailImage,
    fallbackImageSrc,
    alt,
    width,
    height,
    onClick,
    wrapperSx,
}: {
    src?: string;
    thumbnailImage?: {
        src?: string;
        pathForValidate: string; // 썸네일인지 구분할 수 있는 path
    };
    fallbackImageSrc?: string;
    alt?: string;
    width?: string;
    height?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    wrapperSx?: SxProps;
}) {
    const [src, setSrc] = useState(thumbnailImage?.src ?? srcProp);
    const [showSkeleton, setShowSkeleton] = useState(true);

    return (
        <Box
            onClick={onClick}
            sx={{
                position: "relative",
                display: "block",
                ...(width && { width }),
                ...(height && { height }),
                ...wrapperSx,
            }}
        >
            {showSkeleton && (
                <Skeleton
                    width="100%"
                    height="100%"
                    sx={{
                        position: "absolute",
                        zIndex: 1,
                    }}
                />
            )}

            <img
                onLoad={(e: ImageEvent) => {
                    e.currentTarget.style.visibility = "visible";
                    setTimeout(() => {
                        setShowSkeleton(false);
                    }, 0);
                }}
                onError={(e: ImageEvent) => {
                    e.currentTarget.style.visibility = "hidden";
                    if (
                        thumbnailImage &&
                        e.currentTarget.src.includes(thumbnailImage.pathForValidate)
                    ) {
                        setSrc(srcProp);
                    } else if (fallbackImageSrc) {
                        setSrc(fallbackImageSrc);
                    }
                }}
                alt={alt}
                src={src}
                style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    objectFit: "cover",
                }}
            />
        </Box>
    );
}
