import React, { ReactNode } from "react";

import { Backdrop, Collapse, Grid, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

interface BottomSlideModalProps {
    open: boolean;
    onClose?: () => void;
    children?: ReactNode;
    blockBackButton?: boolean;
}

const BottomSlideModal: React.FC<BottomSlideModalProps> = ({ open, onClose, children }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    return (
        <>
            <Grid
                sx={{
                    height: "100%",
                    position: "relative",
                    overscrollBehavior: "none",
                }}
            >
                <Collapse
                    in={open}
                    sx={{
                        backgroundColor: "white",
                        border: "none",
                        borderRadius: "20px 20px 0 0",
                        bottom: 0,
                        left: 0,
                        boxShadow: "5px 5px 20px 0 rgba(0,0,0,0.2)",
                        position: "fixed",
                        width: isMobile ? "100vw" : `100%`,
                        zIndex: 2000,
                        maxHeight: "80vh",
                        overflow: "auto",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </Collapse>
            </Grid>
            <Backdrop open={open} onClick={onClose} sx={{ zIndex: 2 }} />
        </>
    );
};

export default BottomSlideModal;
