import { SvgIcon, SvgIconProps } from "@mui/material";

export function IconLocationDot(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <g clipPath="url(#clip0_8021_17764)">
                    <circle
                        opacity="0.1"
                        cx="20"
                        cy="20"
                        r="22"
                        fill="#FF0A3F"
                        stroke="white"
                        strokeWidth="4"
                    />
                    <circle
                        cx="20"
                        cy="20"
                        r="9.5"
                        fill="#FF0A3F"
                        stroke="white"
                        strokeWidth="3"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8021_17764">
                        <rect width="40" height="40" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}
