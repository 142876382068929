import { ComponentProps } from "react";

import { Button, Typography } from "@mui/material";

import { tmrColorPalette } from "../../consts";

export function BottomFixedButton(props: ComponentProps<typeof Button>) {
    const { children, sx, disabled, ...rest } = props;
    return (
        <Button
            variant="contained"
            color={"primary"}
            {...rest}
            fullWidth
            sx={{
                height: "54px",
                borderRadius: "10px",
                backgroundColor: tmrColorPalette.primary[500],
                ":hover": {
                    backgroundColor: tmrColorPalette.primary[500],
                },
                ...(disabled && {
                    backgroundColor: tmrColorPalette.grey[100],
                    ":hover": {
                        backgroundColor: tmrColorPalette.grey[100],
                    },
                }),
                ...sx,
            }}
        >
            <Typography
                variant="f16B"
                color={{
                    color: disabled ? tmrColorPalette.grey[200] : tmrColorPalette.grey[900],
                }}
            >
                {children}
            </Typography>
        </Button>
    );
}
