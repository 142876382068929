import { RefObject, useEffect, useRef, useState } from "react";

export function useOnScreen(ref: RefObject<HTMLElement>, options?: IntersectionObserverInit) {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [isOnScreen, setIsOnScreen] = useState(false);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            ([entry]) => setIsOnScreen(entry.isIntersecting || entry.intersectionRatio > 0),
            options,
        );
    }, []);

    useEffect(() => {
        observerRef.current && ref.current && observerRef.current.observe(ref.current);

        return () => {
            observerRef.current && observerRef.current.disconnect();
        };
    }, [ref]);

    return isOnScreen;
}
