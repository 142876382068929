import Lottie from "react-lottie";

import LoadingDot from "../lotties/loading-dot.json";

export const LottieLoadingDots = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingDot,
    };

    return <Lottie options={defaultOptions} height={60} width={60} />;
};
