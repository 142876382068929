import { default as MUISkeleton } from "@mui/material/Skeleton/Skeleton";
import { SxProps } from "@mui/material/styles";

export function Skeleton({
    width,
    height,
    sx,
}: {
    width: string;
    height: string;
    sx?: SxProps;
}) {
    return (
        <MUISkeleton
            width={width}
            height={height}
            sx={{
                transform: "none",
                ...sx,
            }}
        />
    );
}
