import { PropsWithChildren, ReactNode, forwardRef } from "react";

import { Box, Divider, SxProps, Typography } from "@mui/material";

import { tmrColorPalette } from "../../consts";

export const Wrapper = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{ handleClickMenuPiece: () => void }>
>(function Wrapper(props, ref) {
    const { children, handleClickMenuPiece, ...rest } = props;
    return (
        <Box
            ref={ref}
            {...rest}
            sx={{
                display: "flex",
                position: "relative",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
                py: "20px",
                cursor: "pointer",
                flexWrap: "nowrap",
            }}
            onClick={handleClickMenuPiece}
        >
            {children}
        </Box>
    );
});

export function InfoWrapper({
    children,
    hasImage,
    isSoldOut,
    isHidden,
    sx,
}: PropsWithChildren<{
    hasImage?: boolean;
    isSoldOut?: boolean;
    isHidden?: boolean;
    sx?: SxProps;
}>) {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                ...(hasImage && {
                    paddingRight: "30px",
                    minWidth: "150px",
                }),
                ...((isSoldOut || isHidden) && { filter: "grayscale(100%)" }),
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}

export function Badges({ children }: PropsWithChildren) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                pb: "10px",
            }}
        >
            {children}
        </Box>
    );
}

export function Label({
    label,
    isSoldOut,
    isHidden,
    soldOutText = "품절",
    hiddenText = "숨겨짐",
    isTextType = false,
}: {
    label: ReactNode;
    isHidden?: boolean;
    hiddenText?: string;
    isSoldOut?: boolean;
    soldOutText?: string;
    isTextType?: boolean;
}) {
    const REGEX_ISLINE = /^-+$/g;

    const labelNode =
        typeof label === "string" && REGEX_ISLINE.test(label) && isTextType ? (
            <Divider
                sx={{
                    width: "100%",
                    border: "1.5px dashed grey",
                    "div:has(&)": {
                        width: "100%",
                    },
                }}
            />
        ) : (
            <Box>
                <Typography
                    variant="Headline1Bold"
                    component="span"
                    color={
                        isSoldOut || isHidden
                            ? tmrColorPalette.grey[400]
                            : tmrColorPalette.grey[900]
                    }
                    sx={{
                        overflow: "hidden",
                        wordBreak: "break-word",
                    }}
                >{`${label}${isSoldOut ? `・${soldOutText}` : ""}${
                    isHidden ? `・${hiddenText}` : ""
                }`}</Typography>
            </Box>
        );

    return labelNode;
}

export function Price({
    value,
    isSoldOut,
    isHidden,
}: {
    value?: string;
    isSoldOut?: boolean;
    isHidden?: boolean;
}) {
    if (value === undefined || value === "") return null;

    return (
        <Box>
            <Typography
                variant="Body1NormalSemiBold"
                color={
                    isSoldOut || isHidden
                        ? tmrColorPalette.grey[400]
                        : tmrColorPalette.grey[900]
                }
                sx={{
                    pt: "4px",
                }}
            >
                {value}
            </Typography>
        </Box>
    );
}

export function Descriptions({
    descriptions,
    isSoldOut,
    isHidden,
}: {
    descriptions?: string[];
    isSoldOut?: boolean;
    isHidden?: boolean;
}) {
    if (!descriptions) return null;

    return (
        <Box>
            <Typography
                variant="Label2Medium"
                color={
                    isSoldOut || isHidden
                        ? tmrColorPalette.grey[400]
                        : tmrColorPalette.grey[400]
                }
                sx={{
                    display: "-webkit-box",
                    pt: "6px",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                }}
            >
                {descriptions.join("\n")}
            </Typography>
        </Box>
    );
}

export function ImageWrapper({
    children,
    isSoldOut,
    isHidden,
    hasImage,
}: PropsWithChildren<{ isSoldOut?: boolean; isHidden?: boolean; hasImage?: boolean }>) {
    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            {hasImage && (
                <>
                    {children}
                    {(isSoldOut || isHidden) && (
                        <Box
                            sx={{
                                position: "absolute",
                                display: "block",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                width: "100px",
                                height: "100px",
                                backgroundColor: `${tmrColorPalette.grey[100]}80`,
                            }}
                        />
                    )}
                </>
            )}
        </Box>
    );
}

export function Image({ src }: { src?: string }) {
    return (
        <img
            src={src}
            loading={"lazy"}
            alt={"메뉴 이미지"}
            style={{
                display: "block",
                width: "100px",
                height: "100px",
                aspectRatio: "1 / 1",
                objectFit: "cover",
                borderRadius: 10,
                border: `1px solid ${tmrColorPalette.grey[100]}`,
            }}
        />
    );
}

export default {
    Wrapper,
    InfoWrapper,
    Badges,
    Label,
    Price,
    Descriptions,
    ImageWrapper,
    Image,
};
